import React, { InputHTMLAttributes } from "react";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import { lowerCase, upperFirst } from "lodash";




export const addToast = (message?: any, type?: any, time?: any) => {

    function sentenceCase(input?: any, lowercaseBefore?: any) {
        input = (input === undefined || input === null) ? '' : input;
        if (lowercaseBefore) { input = input.toLowerCase(); }
        return input.toString().replace(/(^|\. *)([a-z])/g, function (match: any, separator: any, char: any) {
            return separator + char.toUpperCase();
        });
    }

    toast.custom(
        (t) => (
            <Transition
                appear
                show={t.visible}
                className={`p-4 max-w-md w-full ${type === "success" ? "bg-green-600" : "bg-red-500"}  dark:bg-slate-800 shadow-lg rounded-3xl pointer-events-auto  text-slate-900 dark:text-slate-200`}
                enter="transition-all duration-150"
                enterFrom="opacity-0 translate-x-20"
                enterTo="opacity-100 translate-x-0"
                leave="transition-all duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-20"
            >

                <div className="flex items-center justify-center">
                    {/* <div className="flex-shrink-0 pt-0.5">
                        {
                            type === "success" ?
                                <i className="fa fa-check-circle text-xl text-white" aria-hidden="true"></i>

                                :

                                <i className="fa fa-exclamation-triangle text-white text-2xl" aria-hidden="true"></i>


                        }

                        <img
                      className="h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=6GHAjsWpt9&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                      alt=""
                    />
                    </div> */}
                    <div className="flex-1">

                        <p className={`text-base font-medium text-center items-center justify-center ${type != "success" ? "text-white" : "text-white"}`}>
                            {
                                type === "success" ?
                                    <i className="fa fa-check-circle text-xl text-white mr-3" aria-hidden="true"></i>

                                    :

                                    <i className="fa fa-exclamation-triangle text-white text-2xl mr-3" aria-hidden="true"></i>


                            }
                            {message.includes(".") ? sentenceCase(message) : message.charAt(0).toUpperCase() + message.slice(1).toLowerCase()}
                        </p>
                        {/* <p className="mt-1 text-sm text-gray-500">
                      Sure! 8:30pm works great!
                    </p> */}
                    </div>
                </div>

                {/* <div className="border-t border-slate-200 dark:border-slate-700 my-4" /> */}
                {/* {renderProductCartOnNotify({ size })} */}
            </Transition>
        ),
        { position: "bottom-center", id: "nc-product-notify", duration: time ? time : 10000 }
    );
};



