import Axios from "../../Axios";
import axios from "axios";
import jwtDecode from "jwt-decode";
import moment from "moment";
const getToken = localStorage.getItem(`${window.location.hostname}.login`);
console.log("is token avialble", getToken);
let myId = "";
if (getToken) {
  const { data } = JSON.parse(getToken);
  const { id } = jwtDecode(data);
  myId = id;
} else {
  let tempId = localStorage.getItem("tempUser")
  console.log("is yemp avialble", tempId);

  myId = tempId
}

export const GetUserId = () => {
  return myId;
}

export const CurrentUser = async (id = myId) => {
  return await Axios({
    pathname: `api/v1/user/${id === null ? myId : id}`,
    method: "get",
  });
};

export const getBillInfo = async () => {
  return await Axios({
    pathname: `api/v1/billingInformation/${myId}`,
    method: "get",
  });
};
export const getShipInfo = async () => {
  return await Axios({
    pathname: `api/v1/shippingInformation/${myId}`,
    method: "get",
  });
};
export const getUser = async () => {
  return await Axios({
    pathname: `api/v1/user/${myId}`,
    method: "get",
  });
};
export const getAllProducts = async ({
  pto = Number.MAX_SAFE_INTEGER,
  pfrom = 0,
  sort = {
    sortField: "productId",
    sort: "asc",
  },
  pNo = "1",
  limit = Number.MAX_SAFE_INTEGER.toString(),
  featured = "",
  latest = "",
  top = "",
  dfrom = "0",
  dto = "100",
  manufacturer = JSON.stringify([]),
  categories = JSON.stringify([]),
  search = "",
  subId = JSON.stringify([]),
  distinct = "",
  color = "",
  size = "",
  isActive = 1,
  userId = myId,
  hire = "",
  sub2Id = JSON.stringify([]),
  sub3Id = JSON.stringify([]),
  brand = "",
  material = "",
  state = "",
  suburb = ""
}) => {
  return await Axios({
    pathname: `api/v1/product?subId=${subId}&fromPrice=${pfrom}&toPrice=${pto}&sortField=${sort?.sortField}&sortValue=${sort?.sort}&page=${pNo}&limit=${limit}&top=${top}&fromDiscount=${dfrom}&toDiscount=${dto}&mainId=${categories}&manufacturer=${manufacturer}&featured=${featured}&sub2Id=${sub2Id}&sub3Id=${sub3Id}&productName=${search}&latest=${latest}&isActive=${isActive}&distinct=${distinct}&size=${size}&colour=${color}&userId=${userId}&hire=${hire}&brand=${brand}&material=${material}&state=${state}&suburb=${suburb}`,
    method: "get",
  });
};
export const Categories = async () => {
  return await Axios({
    pathname: "api/v1/category/maincategory?auth",
    method: "get",
  });
};
export const Supplier = async () => {
  return await Axios({
    pathname: "api/v1/supplier",
    method: "get",
  });
};
export const getProductById = async (id) => {
  return await Axios({
    pathname: `api/v1/product/${encodeURIComponent(id)}`,
    method: "get",
  });
};
export const getProductById2 = async (id) => {
  return await Axios({
    pathname: `api/v1/product/prodId/${encodeURIComponent(id)}`,
    method: "get",
  });
};
export const getProductById3 = async (id) => {
  return await Axios({
    pathname: `api/v1/product/catId/${id}`,
    method: "get",
  });
};
export const getCartList = async ({
  isQuotation = "0"
}) => {
  return await Axios({
    pathname: `api/v1/cart?displayName=&fromPrice=0&toPrice=${Number.MAX_SAFE_INTEGER}&sortField=displayName&sortValue=asc&page=1&limit=10&userId=${myId}&isQuotation=${isQuotation}
    `,
    method: "get",
  });
};
export const getWishlistList = async ({
  limit = Number.MAX_SAFE_INTEGER
}) => {
  return await Axios({
    pathname: `api/v1/wishlist?displayName=&fromPrice=0&toPrice=${Number.MAX_SAFE_INTEGER}&sortField=displayName&sortValue=asc&page=1&limit=${limit}&userId=${myId}
    `,
    method: "get",
  });
};
export const getOrderHistory = async () => {
  return await Axios({
    pathname: `api/v1/orderhistory/user/${myId}`,
    method: "get",
  });
};
export const getProductComments = async (productId) => {
  return await Axios({
    pathname: `api/v1/comment/?productId=${productId}`,
    method: "get",
  });
};

export const getSubCategory = async (mainId) => {
  return await Axios({
    pathname: `api/v1/category/subcategory?mainId=${mainId}`,
    method: "get",
  });
};
export const getAboutus = async () => {
  return await Axios({
    pathname: "api/v1/aboutus",
    method: "get",
  });
};

export const getFaq = async () => {
  return await Axios({
    pathname: "api/v1/faq",
    method: "get",
  });
};

export const getLogo = async () => {
  return await Axios({
    pathname: "api/v1/settings/logo",
    method: "get",
  });
};

export const getCover = async () => {
  return await Axios({
    pathname: "api/v1/settings/cover",
    method: "get",
  });
};

export const getBanner = async () => {
  return await Axios({
    pathname: "api/v1/settings/banner",
    method: "get",
  });
};

export const getIcon = async () => {
  return await Axios({
    pathname: "api/v1/settings/icon",
    method: "get",
  });
};

export const getCaption = async () => {
  return await Axios({
    pathname: "api/v1/settings/caption",
    method: "get",
  });
};

export const getBrand = async () => {
  return await Axios({
    pathname: "api/v1/settings/brand",
    method: "get",
  });
};

export const getDownloadable = async () => {
  return await Axios({
    pathname: "api/v1/settings/downloadable",
    method: "get",
  });
};
export const getProductByName = async ({ name }) => {
  return await Axios({
    pathname: `api/v1/product/getByName/${encodeURIComponent(name)}`,
    method: "get",
  });
};
export const getProductBySizeAndColor = async ({ name, size, color }) => {
  return await Axios({
    pathname: `api/v1/product/getBySizeColour/${encodeURIComponent(name)}/${encodeURIComponent(size)}/${encodeURIComponent(color)}
    `,
    method: "get",
  });
};
export const getProductBySizeAndMaterial = async ({ name, size, material }) => {
  return await Axios({
    pathname: `api/v1/product/prod/getBySizeMaterial?name=${encodeURIComponent(name)}&size=${size}&material=${material}
    `,
    method: "get",
  });
};

export const getProductBySizeAndColorNew = async ({ productId, size, colour }) => {
  return await Axios({
    pathname: `api/v1/product/prod/getBySizeColour?productId=${encodeURIComponent(productId)}&size=${size}&colour=${encodeURIComponent(colour)}
    `,
    method: "get",
  });
};
export const getCompany = async () => {
  return await Axios({
    pathname: "api/v1/settings/company",
    method: "get",
  });
};

export const getOrderDetails = async () => {
  return await Axios({
    pathname: "api/v1/orderhistory/getAll",
    method: "get",
  });
};
export const getEmailTemplate = async () => {
  return await Axios({
    pathname: "api/v1/email/customertemplate/",
    method: "get",
  });
};

export const getTestimonial = async () => {
  return await Axios({
    pathname: "api/v1/settings/testimonial",
    method: "get",
  });
};

export const getSignUpVerification = async (token) => {
  return await Axios({
    pathname: `api/v1/user/verification/${token}`,
    method: "get",
  });
};

export const getDistricts = async () => {
  // const result = await axios({
  //   method: "get",
  //   url: `https://locatesrilanka.herokuapp.com/districts`,

  //   data: "",
  //   // headers: {
  //   //   Accept: `application/json`,
  //   //   Authorization: `Bearer ${token}`,
  //   //   "Content-Type": "application/json",
  //   // },
  // });

  // const resultData = result.data;
  // return resultData;
  return await Axios({
    pathname: "api/v1/geo/states/",
    method: "get",
  });
};

export const getCities = async (id) => {
  const result = await axios({
    method: "get",
    url: `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-australia-state-suburb&q=&rows=9999&${id ? "refine.ste_name=" + id : "refine"}`,

    data: "",
    // headers: {
    //   Accept: `application/json`,
    //   Authorization: `Bearer ${token}`,
    //   "Content-Type": "application/json",
    // },
  });

  const resultData = result;
  return resultData;
  // return await Axios({
  //   pathname: `api/v1/geo/suburbs/${id}`,
  //   method: "get",
  // });
};

export const getAllCities = async () => {
  // const result = await axios({
  //   method: "get",
  //   url: `https://locatesrilanka.herokuapp.com/cities/`,

  //   data: "",
  //   // headers: {
  //   //   Accept: `application/json`,
  //   //   Authorization: `Bearer ${token}`,
  //   //   "Content-Type": "application/json",
  //   // },
  // });

  // const resultData = result.data;
  // return resultData;

  return await Axios({
    pathname: `api/v1/geo/suburbs`,
    method: "get",
  });
};

export const getFAQs = async (id = myId) => {
  return await Axios({
    pathname: `api/v1/faq/getByUser/${id === null ? myId : id}`,
    method: "get",
  });
};

export const getJobs = async ({
  jobType = JSON.stringify([]),
  sortValue = "desc",
  sortField = "id",
  limit = Number.MAX_SAFE_INTEGER.toString(),
  page = "1",
  country = "",
  state = "",
  suburb = "",
  hourlyRate = "",
  title = "",
  search = "",
  fromHourlyRate = 0,
  toHourlyRate = Number.MAX_SAFE_INTEGER.toString(),
  userId = myId,
  curDateTime = moment().format("YYYY-MM-DD HH:mm:ss"),
}) => {
  return await Axios({
    pathname: `api/v1/job?jobType=${jobType}&sortValue=${sortValue}&sortField=${sortField}&limit=${limit}&page=${page}&country=${country}&state=${state}&suburb=${suburb}&userId=${userId}&fromHourlyRate=${fromHourlyRate}&toHourlyRate=${toHourlyRate}&title=${title}&curDateTime=${curDateTime}
    `,
    method: "get",
  });
};

export const getBlogs = async ({
  category = JSON.stringify([]),
  sortValue = "desc",
  sortField = "id",
  limit = Number.MAX_SAFE_INTEGER.toString(),
  page = "1",
  country = "",
  search = "",

}) => {
  return await Axios({
    pathname: `api/v1/blog?category=${category}&sortValue=${sortValue}&sortField=${sortField}&limit=${limit}&page=${page}&country=${country}&search=${search}&userId=${myId}
    `,
    method: "get",
  });
};

export const getEvents = async ({
  eventType = JSON.stringify([]),
  sortValue = "desc",
  sortField = "startDate",
  limit = Number.MAX_SAFE_INTEGER.toString(),
  page = "1",
  country = "",
  state = "",
  suburb = "",
  hourlyRate = "",
  charge = "",
  search = "",
  fromDate = "1999-01-04",
  toDate = "9999-01-04",
  title = "",
  userId = myId,
  houseType = JSON.stringify([]),
  payType = JSON.stringify([]),
}) => {
  return await Axios({
    pathname: `api/v1/event?eventType=${eventType}&sortValue=${sortValue}&sortField=${sortField}&limit=${limit}&page=${page}&country=${country}&state=${state}&suburb=${suburb}&userId=${userId}&fromDate=${fromDate}&toDate=${toDate}&search=${search}&charge=${charge}&title=${title}&houseType=${houseType}&payType=${payType}
    `,
    method: "get",
  });
};

export const getSellerReviews = async (id = myId) => {
  return await Axios({
    pathname: `api/v1/sellerreview/${id === null ? myId : id}`,
    method: "get",
  });
};

export const getWholeSalers = async ({
  cleaningType = JSON.stringify([]),
  userType = JSON.stringify([]),
  categories = JSON.stringify([]),
  sortValue = "asc",
  sortField = "businessName",
  limit = Number.MAX_SAFE_INTEGER.toString(),
  page = "1",
  country = "",
  state = "",
  suburb = "",
  hourlyRate = "",
  title = "",
  search = "",
  fromHourlyRate = 0,
  toHourlyRate = Number.MAX_SAFE_INTEGER.toString(),
  userId = myId
}) => {
  return await Axios({
    pathname: `api/v1/user?userType=${userType}&cleaningType=${cleaningType}&sortValue=${sortValue}&sortField=${sortField}&limit=${limit}&page=${page}&country=${country}&state=${state}&suburb=${suburb}&userId=${userId}&fromHourlyRate=${fromHourlyRate}&toHourlyRate=${toHourlyRate}&title=${title}&subId=[]&sub2Id=[]&mainId=[]&categories=${categories}`,
    method: "get",
  });
};

export const getJobById = async (id, curDateTime = moment().format("YYYY-MM-DD HH:mm:ss")) => {
  return await Axios({
    pathname: `api/v1/job/${id}?curDateTime=${curDateTime}`,
    method: "get",
  });
};

export const getEventById = async (id) => {
  return await Axios({
    pathname: `api/v1/event/${id}`,
    method: "get",
  });
};

export const GetAllUnits = async () =>
  await Axios({
    pathname: `api/v1/unit`,
    method: "GET",
  });

export const GetAllSubsforMain = async (id, hire = "") =>
  await Axios({
    pathname: `api/v1/subcategory/getByMainId?mainId=${id}&hire=${hire}`,
    method: "GET",
  });

export const GetAllSubs2forMain = async ({ id = "", subId = "" }) =>
  await Axios({
    pathname: `api/v1/subcategory/getByMainIdSubId?mainId=${id}&subId=${subId}`,
    method: "GET",
  });

export const GetAllSubs3forMain = async ({ id = "", subId = "", sub2Id = "" }) =>
  await Axios({
    pathname: `api/v1/subcategory/getByMainIdSubIdSub2Id?mainId=${id}&subId=${subId}&sub2Id=${sub2Id}`,
    method: "GET",
  });

export const GetSizebyUnit = async (id) =>
  await Axios({
    pathname: `api/v1/size/getByUnit/${id}`,
    method: "GET",
  });

export const GetMaincategory = async ({
  marketPlace = "",
  privateStore = "",
  limit = Number.MAX_SAFE_INTEGER.toString(),
  hire = ""
}

) =>
  await Axios({
    pathname: `api/v1/category?marketPlace=${marketPlace}&privateStore=${privateStore}&hire=${hire}`,
    method: "GET",
  });

export const GetUnit = async ({ id = "" }) =>
  await Axios({
    pathname: `api/v1/size?unitId=${id}`,
    method: "GET",
  });
export const GetColours = async () =>
  await Axios({
    pathname: `api/v1/colour`,
    method: "GET",
  });

export const GetSizes = async () =>
  await Axios({
    pathname: `api/v1/size`,
    method: "GET",
  });

export const GetApplicantBYJob = async ({
  jobId = "",
  sortValue = "desc",
  sortField = "id",
  limit = Number.MAX_SAFE_INTEGER.toString(),
  page = "1",
  country = "",
  state = "",
  suburb = "",
  hourlyRate = "",
  title = "",
  search = "",
  fromHourlyRate = 0,
  toHourlyRate = Number.MAX_SAFE_INTEGER.toString(),
  userId = myId
}) => {
  return await Axios({
    pathname: `api/v1/applicant/getbyjobpostid/5?jobId=${jobId}&sortValue=${sortValue}&sortField=${sortField}&limit=${limit}&page=${page}&country=${country}&state=${state}&suburb=${suburb}&userId=${userId}&fromHourlyRate=${fromHourlyRate}&toHourlyRate=${toHourlyRate}&title=${title}
    `,
    method: "get",
  });
};

export const GetAllSubcategory1 = async ({
  marketPlace = "",
  privateStore = "",
}) =>
  await Axios({
    pathname: `api/v1/subcategory/subcategories1?marketPlace=${marketPlace}&privateStore=${privateStore}`,
    method: "GET",
  });

export const GetAllSubcategory2 = async ({
  marketPlace = "",
  privateStore = "",
}) =>
  await Axios({
    pathname: `api/v1/subcategory/subcategories2?marketPlace=${marketPlace}&privateStore=${privateStore}`,
    method: "GET",
  });

export const GetAllSubcategory3 = async ({
  marketPlace = "",
  privateStore = "",
}) =>
  await Axios({
    pathname: `api/v1/subcategory/subcategories3?marketPlace=${marketPlace}&privateStore=${privateStore}`,
    method: "GET",
  });


export const getContracts = async ({
  cleaningType = JSON.stringify([]),
  sortValue = "desc",
  sortField = "id",
  limit = Number.MAX_SAFE_INTEGER.toString(),
  page = "1",
  country = "",
  state = "",
  suburb = "",
  hourlyRate = "",
  title = "",
  search = "",
  fromHourlyRate = 0,
  toHourlyRate = Number.MAX_SAFE_INTEGER.toString(),
  userId = myId,
  curDateTime = moment().format("YYYY-MM-DD HH:mm:ss"),
}) => {
  return await Axios({
    pathname: `api/v1/serviceseeker?cleaningType=${cleaningType}&sortValue=${sortValue}&sortField=${sortField}&limit=${limit}&page=${page}&country=${country}&state=${state}&suburb=${suburb}&userId=${userId}&fromHourlyRate=${fromHourlyRate}&toHourlyRate=${toHourlyRate}&title=${title}&curDateTime=${curDateTime}
      `,
    method: "get",
  });
};

export const CategoriesPopular = async ({
  marketPlace = "",
  privateStore = "",
  limit = Number.MAX_SAFE_INTEGER.toString(),
}) => {
  return await Axios({
    pathname: `api/v1/category/popular?marketPlace=${marketPlace}&privateStore=${privateStore}&limit=${limit}`,
    method: "get",
  });
};

export const GetSiteReviews = async ({
  sortValue = "desc",
  sortField = "id",
  limit = Number.MAX_SAFE_INTEGER.toString(),
  page = "1",

}) => {
  return await Axios({
    pathname: `api/v1/sitereview?sortValue=${sortValue}&sortField=${sortField}&limit=${limit}&page=${page}&userId`,
    method: "get",
  });
};

export const GetTopSelling = async ({
  sortValue = "desc",
  sortField = "id",
  limit = Number.MAX_SAFE_INTEGER.toString(),
  page = "1",
}) => {
  return await Axios({
    pathname: `api/v1/user/topselling?sortValue=${sortValue}&sortField=${sortField}&limit=${limit}&page=${page}&userId`,
    method: "get",
  });
};

export const GetCleaningType = async () =>
  await Axios({
    pathname: `api/v1/cleaningtype`,
    method: "GET",
  });

export const GetRole = async () =>
  await Axios({
    pathname: `api/v1/user/role`,
    method: "GET",
  });

export const CategoriesForShop = async ({
  marketPlace = "",
  privateStore = "",
  sellerId = "",
  hire = "",
}) => {
  return await Axios({
    pathname: `api/v1/category/shop?marketPlace=${marketPlace}&privateStore=${privateStore}&sellerId=${sellerId}&hire=${hire}`,
    method: "get",
  });
};

export const GetSubcategory1ForShop = async ({
  marketPlace = "",
  privateStore = "",
  mainId = JSON.stringify([]),
  sellerId = myId,
  hire = ""
}) =>
  await Axios({
    pathname: `api/v1/subcategory/subcategory1?marketPlace=${marketPlace}&privateStore=${privateStore}&mainId=${mainId}&sellerId=${sellerId}&hire=${hire}`,
    method: "GET",
  });

export const GetSubcategory2ForShop = async ({
  marketPlace = "",
  privateStore = "",
  mainId = JSON.stringify([]),
  sellerId = myId,
  subId = JSON.stringify([]),
  hire = ""
}) =>
  await Axios({
    pathname: `api/v1/subcategory/subcategory2?marketPlace=${marketPlace}&privateStore=${privateStore}&mainId=${mainId}&sellerId=${sellerId}&subId=${subId}&hire=${hire}`,
    method: "GET",
  });

export const GetSubcategory3ForShop = async ({
  marketPlace = "",
  privateStore = "",
  mainId = JSON.stringify([]),
  sellerId = myId,
  subId = JSON.stringify([]),
  sub2Id = JSON.stringify([]),
  hire = ""
}) =>
  await Axios({
    pathname: `api/v1/subcategory/subcategory3?marketPlace=${marketPlace}&privateStore=${privateStore}&mainId=${mainId}&sellerId=${sellerId}&subId=${subId}&sub2Id=${sub2Id}&hire=${hire}`,
    method: "GET",
  });

export const GetApplicantBYContract = async ({
  serviceId = "",
  sortValue = "desc",
  sortField = "id",
  limit = Number.MAX_SAFE_INTEGER.toString(),
  page = "1",
  country = "",
  state = "",
  suburb = "",
  hourlyRate = "",
  title = "",
  search = "",
  fromHourlyRate = 0,
  toHourlyRate = Number.MAX_SAFE_INTEGER.toString(),
  userId = myId
}) => {
  return await Axios({
    pathname: `api/v1/applicant/getbyservicepostid/5?serviceId=${serviceId}&sortValue=${sortValue}&sortField=${sortField}&limit=${limit}&page=${page}&country=${country}&state=${state}&suburb=${suburb}&userId=${userId}&fromHourlyRate=${fromHourlyRate}&toHourlyRate=${toHourlyRate}&title=${title}
      `,
    method: "get",
  });
};

export const getContractById = async (id) => {
  return await Axios({
    pathname: `api/v1/serviceseeker/${id}`,
    method: "get",
  });
};


export const getOrderSummary = async ({
  eventType = JSON.stringify([]),
  sortValue = "desc",
  sortField = "createdAt",
  limit = Number.MAX_SAFE_INTEGER.toString(),
  page = "1",
  country = "",
  state = "",
  suburb = "",
  hourlyRate = "",
  charge = "",
  search = "",
  fromDate = "1999-01-04",
  toDate = "9999-01-04",
  title = "",
  userId = myId,
  sellerId = myId,
  ordType = "",
  houseType = JSON.stringify([]),
  payType = JSON.stringify([]),
  userType = "",
  isHire = ""
}) => {
  return await Axios({
    pathname: `api/v1/orderhistory?sortValue=${sortValue}&sortField=${sortField}&limit=${limit}&page=${page}&country=${country}&state=${state}&suburb=${suburb}&userId=${userId}&sellerId=${sellerId}&fromDate=${fromDate}&toDate=${toDate}&search=${search}&ordType=${ordType}&userType=${userType}&isHire=${isHire}
    `,
    method: "get",
  });
};


export const GetBrands = async () =>
  await Axios({
    pathname: `api/v1/brand`,
    method: "GET",
  });

export const GetMaterials = async () =>
  await Axios({
    pathname: `api/v1/meterial`,
    method: "GET",
  });


export const getCleanT = async ({
  category = JSON.stringify([]),
  sortValue = "desc",
  sortField = "id",
  limit = Number.MAX_SAFE_INTEGER.toString(),
  page = "1",
  country = "",
  search = "",
  userId = ""

}) => {
  return await Axios({
    pathname: `api/v1/cleaningtype/getShop?category=${category}&sortValue=${sortValue}&sortField=${sortField}&limit=${limit}&page=${page}&country=${country}&search=${search}&userId=${userId ? userId : myId}
      `,
    method: "get",
  });
};

export const AppliedJobs = async ({
  jobType = JSON.stringify([]),
  sortValue = "desc",
  sortField = "id",
  limit = Number.MAX_SAFE_INTEGER.toString(),
  page = "1",
  country = "",
  state = "",
  suburb = "",
  hourlyRate = "",
  title = "",
  search = "",
  fromHourlyRate = 0,
  toHourlyRate = Number.MAX_SAFE_INTEGER.toString(),
  userId = myId,
  curDateTime = moment().format("YYYY-MM-DD HH:mm:ss"),
}) => {
  return await Axios({
    pathname: `api/v1/applicant/getByUserId?jobType=${jobType}&sortValue=${sortValue}&sortField=${sortField}&limit=${limit}&page=${page}&country=${country}&state=${state}&suburb=${suburb}&userId=${userId}&fromHourlyRate=${fromHourlyRate}&toHourlyRate=${toHourlyRate}&title=${title}&curDateTime=${curDateTime}
    `,
    method: "get",
  });
};


export const AppliedContracts = async ({
  jobType = JSON.stringify([]),
  sortValue = "desc",
  sortField = "id",
  limit = Number.MAX_SAFE_INTEGER.toString(),
  page = "1",
  country = "",
  state = "",
  suburb = "",
  hourlyRate = "",
  title = "",
  search = "",
  fromHourlyRate = 0,
  toHourlyRate = Number.MAX_SAFE_INTEGER.toString(),
  userId = myId,
  curDateTime = moment().format("YYYY-MM-DD HH:mm:ss"),
}) => {
  return await Axios({
    pathname: `api/v1/applicant/getAppliedContractsByUser?jobType=${jobType}&sortValue=${sortValue}&sortField=${sortField}&limit=${limit}&page=${page}&country=${country}&state=${state}&suburb=${suburb}&userId=${userId}&fromHourlyRate=${fromHourlyRate}&toHourlyRate=${toHourlyRate}&title=${title}&curDateTime=${curDateTime}
    `,
    method: "get",
  });
};

export const OrderbyID = async ({ ID, status = "" }) =>
  await Axios({
    pathname: `api/v1/orderhistory/orderNo/${ID}?status=${status}`,
    method: "GET",
  });

export const MaterialsForShop = async ({
  marketPlace = "",
  privateStore = "",
  sellerId = "",
  hire = "",
}) => {
  return await Axios({
    pathname: `api/v1/meterial/shop?marketPlace=${marketPlace}&privateStore=${privateStore}&sellerId=${sellerId}&hire=${hire}`,
    method: "get",
  });
};

export const BrandsForShop = async ({
  marketPlace = "",
  privateStore = "",
  sellerId = "",
  hire = "",
}) => {
  return await Axios({
    pathname: `api/v1/brand/shop?marketPlace=${marketPlace}&privateStore=${privateStore}&sellerId=${sellerId}&hire=${hire}`,
    method: "get",
  });
};

export const getCleanQuote = async ({
  eventType = JSON.stringify([]),
  sortValue = "desc",
  sortField = "id",
  limit = Number.MAX_SAFE_INTEGER.toString(),
  page = "1",
  country = "",
  state = "",
  suburb = "",
  hourlyRate = "",
  charge = "",
  search = "",
  fromDate = "1999-01-04",
  toDate = "9999-01-04",
  title = "",
  userId = myId,
  sellerId = myId,
  ordType = "",
  houseType = JSON.stringify([]),
  payType = JSON.stringify([]),
  userType = "",
  isHire = ""
}) => {
  return await Axios({
    pathname: `api/v1/applicant/getRequestedQuotationByUser?sortValue=${sortValue}&sortField=${sortField}&limit=${limit}&page=${page}&country=${country}&state=${state}&suburb=${suburb}&userId=${userId}&sellerId=${sellerId}&fromDate=${fromDate}&toDate=${toDate}&search=${search}&ordType=${ordType}&userType=${userType}&isHire=${isHire}
    `,
    method: "get",
  });
};

export const getCleanQuoteProvider = async ({
  eventType = JSON.stringify([]),
  sortValue = "desc",
  sortField = "id",
  limit = Number.MAX_SAFE_INTEGER.toString(),
  page = "1",
  country = "",
  state = "",
  suburb = "",
  hourlyRate = "",
  charge = "",
  search = "",
  fromDate = "1999-01-04",
  toDate = "9999-01-04",
  title = "",
  serviceProviderId = myId,
  sellerId = myId,
  ordType = "",
  houseType = JSON.stringify([]),
  payType = JSON.stringify([]),
  userType = "",
  isHire = ""
}) => {
  return await Axios({
    pathname: `api/v1/applicant/getRequestedQuotationByServiceprovider?sortValue=${sortValue}&sortField=${sortField}&limit=${limit}&page=${page}&country=${country}&state=${state}&suburb=${suburb}&serviceProviderId=${serviceProviderId}&sellerId=${sellerId}&fromDate=${fromDate}&toDate=${toDate}&search=${search}&ordType=${ordType}&userType=${userType}&isHire=${isHire}
    `,
    method: "get",
  });
};

export const VerifyABN = async ({ ID }) => {
  const result = await axios({
    method: "GET",
    url: `https://api.knowfirst.ai/v1/business/view/overview?abn=${ID}`,
    // data: obj,
    headers: {
      Accept: `application/json`,
      // Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "x-api-key": "821-tXZXoFdduKBV4Vl1j7gB9jsBZp2c1WdawvL7",
      // "Access-Control-Allow-Origin": '#*#',
      // "Access-Control-Allow-Methods ": "GET, POST, OPTIONS",
      // "Access-Control-Allow-Headers": "Origin, Content-Type, Accept",
      // "Access-Control-Allow-Credentials": 'true',
    },
  });

  const resultData = result.data;
  return resultData;
}