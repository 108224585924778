import React, { useEffect, useMemo, useState } from "react";
import "./loader.scss"


export default function LoadingSpinner({ open }: { open?: any }) {


  return (
    <>
      {open === true ?

        <div className="fixed inset-0 z-[999999] bg-white dark:bg-[#002930]">
          <div className="flone-preloader">
            <span></span>
            <span></span>
          </div>
        </div>
        :
        <></>
      }

    </>

  );
}