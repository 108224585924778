import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { getCompany } from "Services/API/Get";
import Img from "images/Footer.png"

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Quick Links",
    menus: [
      // { href: "/servicing", label: "Repairs and Servicing" },
      // { href: "/shop", label: "Shop" },
      { href: "/sellers", label: "Cleaning Supplies" },
      { href: "/rent", label: "Rent Machinery" },
      { href: "/wholesalers", label: "Service Provider" },
      { href: "/contracts", label: "Cleaning Contracts" },
      { href: "/jobs", label: "Jobs" },

    ],
  },
  {
    id: "1",
    title: "Shipping",
    menus: [
      // { href: "/shipping", label: "Shipping" },
      { href: "/conditions", label: "Privacy Policy" },
      { href: "/cancellation", label: "Cancellation Policy" },
      // { href: "/faqs", label: "FAQs" },

    ],
  },
  {
    id: "1",
    title: "Contact Us",
    menus: [
      // { href: "/shipping", label: "Shipping" },
      { href: "", label: "Support Team" },
      { href: "", label: "Compliance Team" },
      // { href: "/faqs", label: "FAQs" },

    ],
  },
  // {
  //   id: "2",
  //   title: "Resources",
  //   menus: [
  //     { href: "#", label: "Best practices" },
  //     { href: "#", label: "Support" },
  //     { href: "#", label: "Developers" },
  //     { href: "#", label: "Learn design" },
  //   ],
  // },
  // {
  //   id: "4",
  //   title: "Community",
  //   menus: [
  //     { href: "#", label: "Discussion Forums" },
  //     { href: "#", label: "Code of Conduct" },
  //     { href: "#", label: "Contributing" },
  //     { href: "#", label: "API Reference" },
  //   ],
  // },
];



const Footer: React.FC = () => {
  const [company, setcompany] = useState<any>("");
  const [lopen, setlopen] = useState<any>(false);

  const CompanyGet = () => {
    setlopen(true);
    getCompany()
      .then((res) => {
        setlopen(false);
        console.log(res.data);
        console.log("company res>>>", res.data[res.data.length - 1])
        setcompany(res.data[res.data.length - 1]);
        // setLogo(res.data[res.data.length - 1].image)
        // this.setState({
        //   logo: res.data[res.data.length - 1].image,
        // });
      })
      .catch((error) => {
        setlopen(false);
        console.error(error);
      });
  };

  React.useEffect(() => {
    CompanyGet();

  }, []);


  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm lg:ml-[90px]">
        <h2 className={`font-bold ${index === 1 ? "text-transparent" : "text-[#002930]"}  dark:text-[#002930] text-base`}>
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <NavLink
                to={{ pathname: item.href }}
                key={index}
                className="text-[#002930] dark:text-[#002930] hover:text-slate-900 dark:hover:text-slate-900"
              // href={item.href}
              // target="_blank"
              // rel="noopener noreferrer"
              >
                {item.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div>
      {/* <div className="nc-Footer relative py-20 lg:pt-28 lg:pb-24 dark:border-neutral-700 bg-[#FCFCFC]">
        <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 lg:gap-x-35 ">
          <div className="grid grid-cols-1 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
            <div className="col-span-1 md:col-span-1">
              <Logo footer={true} />
            </div>
            <div className="col-span-1 flex items-center md:col-span-3">
              


            </div>
            <div className="col-span-1 flex items-center md:col-span-3">

              <span className="text-[#002930] dark:text-[#002930] text-xs" >
               
                Supporting local cleaning businesses and enjoying a seamless shopping experience.
              </span>
             
            </div>
          </div>

          {widgetMenus.map(renderWidgetMenuItem)}
          <div className="text-sm xl:ml-[90px]">
            <h2 className="font-bold text-[#002930] dark:text-[#002930] text-base">
              Available Soon
            </h2>
            <ul className="mt-5 space-y-4">

              <img src={Img} className="h-[100px] -ml-2 cursor-pointer" />

             

            </ul>
          </div>
        </div>
      </div> */}
      <div className="w-full bg-[#184d8c]">

        <div className="w-full h-full flex flex-col md:flex-row justify-center items-center md:px-16 py-6 md:py-5 text-center">

          {/* <div className="flex space-x-5">

            <div className="w-12 h-12 rounded-full border border-slate-200 flex items-center justify-center text-slate-100 hover:bg-white hover:text-slate-900 cursor-pointer">
              <i className="lab la-facebook-f text-4xl "></i>
            </div>

            <div className="w-12 h-12 rounded-full border border-slate-200 flex items-center justify-center text-slate-100 hover:bg-white hover:text-slate-900 cursor-pointer">
              <i className="lab la-instagram text-4xl "></i>
            </div>

            <div className="w-12 h-12 rounded-full border border-slate-200 flex items-center justify-center text-slate-100 hover:bg-white hover:text-slate-900 cursor-pointer">
              <i className="lab la-linkedin text-4xl "></i>
            </div>

          </div> */}

          <div>
            <span className="flex text-base text-slate-100 mt-2 md:mt-0 text-center justify-center">InfinityCs Support Team
            </span>

            {/* <a href="" className="flex text-sm text-slate-100 mt-2 md:mt-0 text-center justify-center">Infinitycs support team</a>

            <a href="" className="flex text-sm text-slate-100 mt-2 md:mt-0 text-center justify-center">  3/26-28 Verdun Drive , Narre Warren , VIC 3805</a> */}

            <a href="mailto:support@infinitycs.com.au" className="flex text-sm text-slate-100 mt-2 md:mt-0 text-center justify-center">support@infinitycs.com.au</a>

            {/* <a href="tel:0426146242" className="flex text-sm text-slate-100 mt-2 md:mt-0 text-center justify-center">0426146242</a> */}

          </div>

        </div>

      </div>
    </div>
  );
};

export default Footer;
