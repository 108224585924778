import axios from "axios";
import Axios from "../../Axios";

export const RegisterUser = async (obj) =>
  await Axios({
    pathname: "api/v1/user",
    obj: obj,
  });

export const LoginQuery = async (obj) =>
  await Axios({
    pathname: "api/v1/login",
    obj: obj,
  });
export const AddBillInfo = async (obj) =>
  await Axios({
    pathname: "api/v1/billinginformation",
    obj: obj,
  });
export const AddShipInfo = async (obj) =>
  await Axios({
    pathname: "api/v1/shippingInformation",
    obj: obj,
  });

export const postContacts = async (obj) =>
  await Axios({
    pathname: `api/v1/email`,
    obj: obj,
  });
export const postQuote = async (obj) =>
  await Axios({
    pathname: `api/v1/email/quote`,
    obj: obj,
  });
export const addToCard = async (obj) =>
  await Axios({
    pathname: `api/v1/cart`,
    obj: obj,
  });
export const addToWishlist = async (obj) =>
  await Axios({
    pathname: `api/v1/wishlist`,
    obj: obj,
  });

export const OrderSave = async (obj) =>
  await Axios({
    pathname: `api/v1/orderhistory/`,
    obj: obj,
  });
export const CommentSave = async (obj) =>
  await Axios({
    pathname: `api/v1/comment/`,
    obj: obj,
  });
export const AddReply = async (obj) =>
  await Axios({
    pathname: `api/v1/reply/`,
    obj: obj,
  });
export const resetPassword = async (obj) =>
  await Axios({
    pathname: `api/v1/login/reset`,
    obj: obj,
  });
export const updatePassword = async (obj) =>
  await Axios({
    pathname: `api/v1/login/update`,
    obj: obj,
  });
export const signUpVerification = async (obj) =>
  await Axios({
    pathname: `api/v1/user/verification/`,
    obj: obj,
  });

export const postFaq = async (obj) =>
  await Axios({
    pathname: `api/v1/faq/`,
    obj: obj,
  });

export const postJobs = async (obj) =>
  await Axios({
    pathname: `api/v1/job/`,
    obj: obj,
  });

export const postSellerReview = async (obj) =>
  await Axios({
    pathname: `api/v1/sellerreview/`,
    obj: obj,
  });

export const postBlogs = async (obj) =>
  await Axios({
    pathname: `api/v1/blog/`,
    obj: obj,
  });

export const postEvent = async (obj) =>
  await Axios({
    pathname: `api/v1/event/`,
    obj: obj,
  });

export const postApplication = async (obj) =>
  await Axios({
    pathname: `api/v1/applicant/`,
    obj: obj,
  });

export const CreateProduct = async ({ obj }) =>
  await Axios({
    pathname: `api/v1/product/`,
    obj: obj,
  });

export const CreateSubCategory = async ({ obj }) =>
  await Axios({
    pathname: `api/v1/subcategory/`,
    obj: obj,
  });

export const CreateMain = async ({ obj }) =>
  await Axios({
    pathname: `api/v1/category/`,
    obj: obj,
  });

export const AddSubsForMain = async ({ obj, id }) =>
  await Axios({
    pathname: `api/v1/category/assignSubcategory/${id}`,
    obj: obj,
  });

export const AddSubsForSubId = async ({ obj, id, subId }) =>
  await Axios({
    pathname: `api/v1/category/assignSubcategory2/${id}/${subId}`,
    obj: obj,
  });

export const AddSubsForSub2Id = async ({ obj, id, subId, sub2Id }) =>
  await Axios({
    pathname: `api/v1/category/assignSubcategory3/${id}/${subId}/${sub2Id}`,
    obj: obj,
  });

export const postContracts = async (obj) =>
  await Axios({
    pathname: `api/v1/serviceseeker/`,
    obj: obj,
  });

export const postApplicationContract = async (obj) =>
  await Axios({
    pathname: `api/v1/applicant/applyservice`,
    obj: obj,
  });


export const PostQuote = async ({ obj, token }) => {
  const result = await axios({
    method: "post",
    url: `https://app.staging.shippit.com/api/3/quotes`,
    data: obj,
    headers: {
      Accept: `application/json`,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": '#*#',
      "Access-Control-Allow-Methods ": "GET, POST, OPTIONS",
      "Access-Control-Allow-Headers": "Origin, Content-Type, Accept",
      "Access-Control-Allow-Credentials": 'true',
    },
  });

  const resultData = result;
  return resultData;
  // return await Axios({
  //   pathname: `api/v1/geo/suburbs/${id}`,
  //   method: "get",
  // });
};



export const PostQuoteApi = async (obj) => {
  return await Axios({
    pathname: `api/v1/cart/getShippit`,
    obj: obj,
  });
};

export const postCleanTypes = async (obj) =>
  await Axios({
    pathname: `api/v1/cleaningtype/`,
    obj: obj,
  });

export const StripeConnect = async (obj) =>
  await Axios({
    pathname: `api/v1/user/stripeConnect`,
    obj: obj,
  });

export const postSiteReview = async (obj) =>
  await Axios({
    pathname: `api/v1/sitereview/`,
    obj: obj,
  });

export const OrderRequest = async (obj) =>
  await Axios({
    pathname: `api/v1/orderhistory/orderRequest`,
    obj: obj,
  });

export const OrderHireRequest = async (obj) =>
  await Axios({
    pathname: `api/v1/orderhistory/orderHireRequest`,
    obj: obj,
  });

export const CleaningRequest = async (obj) =>
  await Axios({
    pathname: `api/v1/applicant/requestQuotation`,
    obj: obj,
  });

export const PostTicket = async (obj) =>
  await Axios({
    pathname: `api/v1/ticket`,
    obj: obj,
  });


