import React, { FC, useState, useEffect, useRef } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV } from "Constant/System";
import _, { lowerCase, map, upperFirst } from "lodash";
import { CleaningRequest, OrderHireRequest, postJobs, postQuote, PostTicket } from "Services/API/Post";
import { addToast } from "shared/Toast";
import { CurrentUser, GetUserId, getCities, getDistricts, getProductById2 } from "Services/API/Get";
import { Button, Grid, TextField, Typography, Autocomplete, Box } from "@mui/material";
import { UpdateJobs, UpdatePackage } from "Services/API/Put";
import { useHistory } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import moment from "moment";
import SubData from "data/australian_postcodes.json";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoadingSpinner from "shared/LoadingSpinner";


const theme = createTheme({
    palette: {
        primary: {
            main: "#024022",
        },
        secondary: {
            main: "#024022",
        },
    },
});

const CATEG = [
    "Website",
    "Ecomfixerr",
    "Kitto-Cloud Kitchen CRM",
    "Tidytraderr",
    "Email Services",
    "Other"
]



const Jobs = ({ selectedJob = null, onCloseModalQuickView, quoteDetails = null, type = null }) => {

    const [images, setImages] = useState(selectedJob ? JSON.parse(selectedJob.document) : "");

    const [title, settitle] = useState(selectedJob ? selectedJob.title : "");
    const [jobType, setjobType] = useState(selectedJob ? selectedJob.jobType : "");
    const [country, setcountry] = useState(selectedJob ? selectedJob.country : "");
    const [homeDistrict, sethomeDistrict] = useState(selectedJob ? selectedJob.homeDistrict : "");
    const [homeCity, sethomeCity] = useState(selectedJob ? selectedJob.homeCity : "");
    const [duration, setduration] = useState(selectedJob ? selectedJob.duration : "");
    const [description, setdescription] = useState(selectedJob ? selectedJob.description : "");
    const [currency, setcurrency] = useState(selectedJob ? selectedJob.currency : "");
    const [rate, setrate] = useState(selectedJob ? selectedJob.hourlyRate : "");
    const [expire, setexpire] = useState(selectedJob ? selectedJob.expiryDate : "");
    const [Keywords, setKeywords] = useState(selectedJob ? JSON.parse(selectedJob.keywords).join(" ") : "");

    const [errimages, seterrImages] = useState([]);

    const [errtitle, seterrtitle] = useState(false);
    const [errjobType, seterrjobType] = useState(false);
    const [errcountry, seterrcountry] = useState(false);
    const [errsuburb, seterrsuburb] = useState(false);
    const [errduration, seterrduration] = useState(false);
    const [errdescription, seterrdescription] = useState(false);
    const [errcurrency, seterrcurrency] = useState(false);
    const [errrate, seterrrate] = useState(false);
    const [errexpire, seterrexpire] = useState(false);
    const [errKeywords, seterrKeywords] = useState(false);
    const [errhomeDistrict, seterrhomeDistrict] = useState(false);
    const [errhomeCity, seterrhomeCity] = useState(false);

    const [homeDistrictId, sethomeDistrictId] = useState(0);
    const [allHCities, setallHCities] = useState([]);
    const [allHDistricts, setallHDistricts] = useState([]);

    const [currUser, setcurrUser] = useState("");

    const [packageId, setpackageId] = useState("");

    const [lOpen, setlOpen] = useState(false);

    const [errpackages, seterrpackages] = useState(false);

    const history = useHistory();

    const [newSubArray, setnewSubArray] = useState([]);
    const [newSubs, setnewSubs] = useState([]);

    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [mobile, setmobile] = useState("");
    const [email, setemail] = useState("");
    const [errmobile, seterrmobile] = useState("");

    const [erremail, seterremail] = useState(false);
    const [errfirstName, seterrfirstName] = useState("");
    const [errlastName, seterrlastName] = useState("");

    React.useEffect(() => {

        let newSubArray = JSON.parse(JSON.stringify(SubData));

        newSubArray = newSubArray?.map((d) => {
            if (d.type === "Delivery Area") {

                return {
                    ...d,
                    locality: upperFirst(lowerCase(d.locality)),
                };

            }

        });

        newSubArray = _.compact(newSubArray)

        setnewSubArray(_.orderBy(newSubArray, ['locality'], ['asc']));

        console.log(">>>>>>>subs", newSubArray.filter((u) => u.state === 0))


    }, []);

    const packageSelect = (item) => {

        console.log("pkg>>>>", item)

        let obj = {}

        if (item === "1") {

            obj = {
                email: currUser?.email,
                amountPayable: "FREE",
                packageDetails: [
                    {
                        packagePrice: "FREE",
                        qty: 1,
                        packageName: "FREE" + " " + "Subscription",
                        days: 7
                    }
                ],
                packageId: 1,
                userId: currUser?.id,
            }


        } else if (item === "2") {

            obj = {
                email: currUser?.email,
                amountPayable: "15",
                packageDetails: [
                    {
                        packagePrice: "15",
                        qty: 1,
                        packageName: "Basic" + " " + "Subscription",
                        days: 28

                    }
                ],
                packageId: 2,
                userId: currUser?.id,
            }



        } else if (item === "3") {

            obj = {
                email: currUser?.email,
                amountPayable: "30",
                packageDetails: [
                    {
                        packagePrice: "30",
                        qty: 1,
                        packageName: "Plus" + " " + "Subscription",
                        days: 84
                    }
                ],
                packageId: 2,
                userId: currUser?.id,
            }


        }

        // let obj = {
        //     email: currUser?.email,
        //     amountPayable: item.pricing.split("$")[1],
        //     packageDetails: [
        //         {
        //             packagePrice: item.pricing.split("$")[1],
        //             qty: 1,
        //             packageName: item.name + " " + "Subscription",
        //         }
        //     ],
        //     packageId: item.id,
        //     userId: currUser?.id,
        // }

        console.log("obj>>>", obj)

        setlOpen(true);

        UpdatePackage(obj, currUser?.id)
            .then((res) => {

                console.log("res>>>>", res.data)

                if (res.data !== undefined) {
                    if (res.data === "success") {
                        history.push("/profile");
                    } else {
                        window.location.href = res.data;
                    }

                }

            })
            .catch((error) => {
                setlOpen(false);
                console.log("error", error);
                console.log("current user error", error.response.data.message);
                if (error.response.data.statusCode === 401) {
                    history.push("/login");
                }
            });

    }

    // React.useEffect(() => {

    //     if (packageId) {
    //         packageSelect(packageId);
    //     }

    // }, [packageId]);

    React.useEffect(() => {
        listDistricts();
        getCurrentUser();

    }, []);

    React.useEffect(() => {
        if (homeDistrictId !== 0) {
            listHomecities();
        }
    }, [homeDistrictId]);

    const listDistricts = () => {
        getDistricts()
            .then((res) => {
                console.log("districts>>>", res);
                setallHDistricts(res.data);
                // setallODistricts(res.data);
            })
            .catch((error) => {
                console.log("Error", error.response);
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });
    };
    const listHomecities = () => {
        getCities(homeDistrict)
            .then((res) => {
                console.log("cities>>>", res.data.records);
                setallHCities(res.data.records);
            })
            .catch((error) => {
                console.log("Error", error.response);
                setallHCities([])
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });
    };

    const handleChangeHdist = (e, value) => {
        if (value !== null) {
            sethomeDistrict(value.name_en);
            seterrhomeDistrict(false);
            sethomeDistrictId(value.id);

        } else {
            sethomeDistrict("");
            sethomeDistrictId(1);
        }

        console.log("state>>", value);
        setnewSubs(newSubArray.filter((u) => u.state === value.id))

    };
    const handleChangeHcity = (e, value) => {
        if (value !== null) {
            // console.log(">><<", value.fields.scc_name)
            sethomeCity(value.locality);
            // sethomepostCode(value.postcode);
            // sethomeCity(value.fields.scc_name);
            // sethomepostCode(value.postcode);
            seterrhomeCity(false);
            // seterrhomepostCode(false);
        } else {
            sethomeCity("");
            // sethomepostCode("");

        }

        console.log("city>>", value);
    };


    const saveJob = async (e) => {

        const phoneRegex =
            /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

        e.preventDefault();

        if (!firstName) {
            seterrfirstName(true);
        }

        if (!lastName) {
            seterrlastName(true);
        }
        if (!email) {
            seterremail(true);
        }
        // if (!description) {
        //     seterrdescription(true);
        // }

        if (!phoneRegex.test(mobile)) {
            if (mobile === "") {
                seterrmobile("This field is required");
            } else {
                seterrmobile("Please enter valid number");
            }
        }

        // if (!Keywords) {
        //     seterrKeywords(true);
        // }
        // if (images.length === 0) {
        //     seterrImages(true);
        // }

        quoteDetails.name = upperFirst(lowerCase(firstName)) + " " + upperFirst(lowerCase(lastName));
        quoteDetails.email = email;
        quoteDetails.phone = mobile;
        quoteDetails.description = description;
        quoteDetails.message = description;
        quoteDetails.firstName = upperFirst(lowerCase(firstName))
        quoteDetails.lastName = upperFirst(lowerCase(lastName))



        let prodDetails = ""

        let finalobj = {}

        if (type != "cleaning") {



            await getProductById2(quoteDetails?.productId)
                .then((res) => {

                    console.log(">>Res", res)

                    const { images, description, options, priceDetails, imgArray, hirePrices, deliveryInfo, ...prodRest } = res.data[0];

                    res.data[0].productDetails = prodRest;
                    res.data[0].productDetails.qty = quoteDetails.qty;
                    res.data[0].productDetails.productPrice = quoteDetails.unitPrice;
                    res.data[0].productDetails.unitprice = quoteDetails.unitPrice;
                    //res.data[0].productDetails.id = item.productId;
                    res.data[0].productDetails.supplierId = quoteDetails.selectedProduct.supplierId;
                    res.data[0].productDetails.supplierName = quoteDetails.selectedProduct.businessName;
                    res.data[0].productDetails.fromDate = quoteDetails.fromDate;
                    res.data[0].productDetails.toDate = quoteDetails.toDate;
                    res.data[0].productDetails.type = quoteDetails.days;


                    prodDetails = res.data[0]
                })
                .catch((error) => {
                    console.log("Error", error.response);
                    // this.setState({ product: "" });
                });

            console.log(">>>>>>>prodDetails", prodDetails)

            let orderMyNo = `IN-${Math.floor(100000000 + Math.random() * 900000000)}`;

            const obj2 = {
                // productId: item?.productId,
                orderNo: `${orderMyNo}`.trim().toString(),
                invoiceNo: `${orderMyNo}`.trim().toString(),
                userId: currUser?.id,
                userName: firstName,
                email: email,
                qty: prodDetails.qty,
                productName: prodDetails?.productName,
                productId: prodDetails?.id,
                itemTotal: parseFloat(quoteDetails.unitPrice).toFixed(2),
                shippingAddress: currUser?.homeAddress,
                billingAddress: currUser?.homeAddress,
                productPrice: quoteDetails.unitPrice,
                // shippingCost: shippingcost,
                amountPayable: parseFloat(quoteDetails.unitPrice).toFixed(2),
                payment: "Card",
                date: moment().format("lll"),
                productDetails: [prodDetails?.productDetails],
                image: [prodDetails?.images],
                customerDetails: {
                    customer: quoteDetails.name,
                    // billing: obj,
                    // shipping: obj,
                },
                firstName: upperFirst(lowerCase(firstName)),
                lastName: upperFirst(lowerCase(lastName)),
                phoneNumber: mobile,
                billingState: currUser?.homeDistrict,
                shippingState: currUser?.homeDistrict,
                billingSuburb: currUser?.homeCity,
                shippingSuburb: currUser?.homeCity,
                billingpostCode: currUser?.homepostCode,
                shippingpostCode: currUser?.homepostCode,
                emailBody: "",
                pdfName: ``,
                emailTemplate: "",
                subject: "",
                shippingCost: 0,
                hire: 1,
                createdAt: moment().format("YYYY-MM-DD HH:mm:ss")
            };

            finalobj = { ...quoteDetails, ...obj2 }

            console.log("obj>>>>", [finalobj])

        }

        if (
            firstName !== "" &&
            lastName !== "" &&
            email !== "" &&
            mobile !== "" &&
            description !== "" &&
            phoneRegex.test(mobile)
        ) {
            setlOpen(true);
            if (type != "cleaning") {
                OrderHireRequest({ orders: [finalobj] })
                    .then((res) => {
                        setlOpen(false);
                        addToast("Quotation Requested Successfully", "success");
                        setfirstName("");
                        setlastName("");
                        setemail("");
                        setmobile("");
                        setdescription("");

                        onCloseModalQuickView();

                    })
                    .catch((error) => {
                        setlOpen(false);
                        console.log("Error", error.response);
                        addToast(error.response.data.message, "warning");
                        // setOpenToast(true);
                        // setColor(COLORS.Orange);
                        // setAlertClass("error");
                        // setMsg(error.response.data.message);
                    })

            } else {

                CleaningRequest(quoteDetails)
                    .then((res) => {
                        setlOpen(false);
                        addToast("Quotation Requested Successfully", "success");
                        setfirstName("");
                        setlastName("");
                        setemail("");
                        setmobile("");
                        setdescription("");

                        onCloseModalQuickView();

                    })
                    .catch((error) => {
                        setlOpen(false);
                        console.log("Error", error.response);
                        addToast(error.response.data.message, "warning");
                        // setOpenToast(true);
                        // setColor(COLORS.Orange);
                        // setAlertClass("error");
                        // setMsg(error.response.data.message);
                    })


            }
        }


    }

    const addTicket = async (e) => {

        const phoneRegex =
            /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

        e.preventDefault();

        if (!firstName) {
            seterrfirstName(true);
        }

        if (!lastName) {
            seterrlastName(true);
        }
        if (!email) {
            seterremail(true);
        }

        if (!description) {
            seterrdescription(true);
        }

        if (!phoneRegex.test(mobile)) {
            if (mobile === "") {
                seterrmobile("This field is required");
            } else {
                seterrmobile("Please enter valid number");
            }
        }

        if (!jobType) {
            seterrjobType(true);
        }

        let orderMyNo = `T-${Math.floor(100000000 + Math.random() * 900000000)}`;

        let obj = {
            referenceNo: orderMyNo,
            name: firstName,
            product: jobType,
            phone: mobile,
            email: email,
            businessName: lastName,
            description: description,
            status: "Pending",
            image: images,
            createdAt: moment().format("YYYY-MM-DD")
        }

        console.log("obj>>>>", obj)


        if (
            firstName !== "" &&
            lastName !== "" &&
            email !== "" &&
            mobile !== "" &&
            description !== "" &&
            phoneRegex.test(mobile) &&
            jobType
        ) {

            PostTicket(obj)
                .then((res) => {
                    setlOpen(false);
                    addToast("Ticket has been created successfully", "success");
                    setfirstName("");
                    setlastName("");
                    setemail("");
                    setmobile("");
                    setdescription("");
                    setImages("");
                    setjobType("")

                    // onCloseModalQuickView();

                })
                .catch((error) => {
                    setlOpen(false);
                    console.log("Error", error.response);
                    addToast(error.response.data.message, "warning");
                    // setOpenToast(true);
                    // setColor(COLORS.Orange);
                    // setAlertClass("error");
                    // setMsg(error.response.data.message);
                })


        }



    }

    const getCurrentUser = () => {
        CurrentUser()
            .then((res) => {
                if (res.statusCode === 200) {
                    const cData = res.data[0];
                    // setUser(cData);
                    const obj = {
                        id: cData.id,
                        email: cData?.email,
                        firstName: cData?.firstName,
                        lastName: cData?.lastName,
                        phone: cData?.mobileNumber,
                    };
                    //   this.setState({
                    //     firstName: cData?.firstName,
                    //     lastName: cData?.lastName,
                    //     email: cData?.email,
                    //     tele: cData?.phone,
                    //   });
                    console.log("<><><><>", cData);
                    setcurrUser(cData);

                    setfirstName(cData?.firstName);
                    setlastName(cData?.lastName);
                    setmobile(cData?.contactNumber !== null ? cData?.contactNumber : "");
                    setemail(cData?.email);
                    setpackageId(cData.packageId ? cData.packageId : "");

                    localStorage.setItem(
                        `${window.location.hostname}.profile`,
                        JSON.stringify(obj)
                    );
                }
            })
            .catch((error) => {
                console.log("error", error);
                console.log("current user error", error.response.data.message);
            });
    };


    return (
        <>
            <LoadingSpinner open={lOpen} />
            <div className="flex justify-center w-full mt-6">

                <span className="block text-3xl font-normal flex justify-center w-full text-center">

                    Submit a ticket
                </span>


            </div>
            <div className="w-full flex justify-center px-3 mb-10">

                <div className="w-full md:w-9/12 lg:w-7/12 xl:w-[45%]">

                    <div className="w-full mt-6 space-y-4">

                        <div>
                            <Label className="text-sm">Product Type</Label>

                            <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                // defaultValue={{ value: category }}
                                value={jobType}

                                // id="combo-box-demo"
                                options={CATEG}
                                getOptionLabel={(option) => option}
                                onChange={(e, value) => {
                                    setjobType(value);
                                    seterrjobType(false);

                                    // setCountry(value);
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select a product type" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            />


                            {/* <Select className="mt-1.5"
                                onChange={(e) => {
                                    setjobType(e.target.value);
                                    seterrjobType(false);
                                }}
                                value={jobType}
                            >
                                <option value={""} className="" >Select a job type</option>
                                <option value="Seasonal">Seasonal</option>
                                <option value="Casual">Casual</option>
                                <option value="Temporary">Temporary</option>
                                <option value="Fixed">Fixed term contract</option>
                                <option value="Part Time">Permanent – Part Time</option>
                                <option value="Full Time">Permanent – Full time</option>

                            </Select> */}
                            {errjobType && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>

                        <div>
                            <Label className="text-sm">Full Name</Label>
                            <Input className="mt-1.5"

                                placeholder="Full Name"
                                onChange={(e) => {
                                    setfirstName(e.target.value);
                                    seterrfirstName(false);
                                }}
                                value={firstName}

                            />
                            {errfirstName && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">Business Name</Label>
                            <Input className="mt-1.5"

                                placeholder="Business Name"
                                onChange={(e) => {
                                    setlastName(e.target.value);
                                    seterrlastName(false);
                                }}
                                value={lastName}

                            />
                            {errlastName && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">Email</Label>
                            <div className="mt-1.5 flex">
                                <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                    <i className="text-xl las la-envelope"></i>
                                </span>
                                <Input
                                    className="!rounded-l-none"
                                    type="email"
                                    placeholder="example@email.com"
                                    onChange={(e) => {
                                        setemail(e.target.value);
                                        seterremail(false);
                                    }}
                                    value={email}
                                />

                            </div>
                            {erremail && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">Contact number</Label>
                            <div className="mt-1.5 flex">
                                <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                    <i className="text-xl las la-phone-volume"></i>
                                </span>
                                <Input
                                    className="!rounded-l-none"
                                    // defaultValue="003 888 232"
                                    placeholder="Enter Mobile"
                                    onChange={(e) => {
                                        setmobile(e.target.value);
                                        seterrmobile("");
                                    }}
                                    value={mobile}
                                />
                            </div>
                            {errmobile && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    {errmobile}
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">Description</Label>
                            <Textarea className="mt-1.5" rows={8}
                                placeholder="Enter description"
                                onChange={(e) => {
                                    setdescription(e.target.value);
                                    seterrdescription(false);
                                }}
                                value={description}
                            />
                            {errdescription && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div className="w-full mt-3 xl:mt-0 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                            <div className="grid grid-cols-10 gap-5 pl-4 pr-5">
                                {[1]?.map((faker, fakerKey) => (
                                    <>
                                        {
                                            images &&

                                            <div
                                                // key={fakerKey}
                                                className="col-span-5 md:col-span-2 h-28 relative image-fit cursor-pointer zoom-in"
                                            >
                                                <img
                                                    className="rounded-md"
                                                    alt="Midone - HTML Admin Template"
                                                    src={images?.includes('/uploads/') === true ? IMAGE_SERVER_URL_DEV + images : images}
                                                />
                                                {/* <div
                                                    className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 -mr-2 -mt-2"
                                                >
                                                    <div
                                                        onClick={(e) => {

                                                            var array = [...images]
                                                            array.splice(fakerKey, 1);
                                                            setImages(array)

                                                        }}
                                                    >
                                                       
                                                        <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                                    </div>
                                                </div> */}


                                            </div>
                                        }

                                        {/* <div className="form-check">
                                            <input id="checkbox-switch-" className="form-check-input w-5 h-5 bg-gray-200 relative rounded-full text-white right-10 top-[80px]" type="checkbox"
                                                checked={faker?.thumbnail}

                                                onChange={(e) => {
                                                    var array = [...images]
                                                    const index = _.map(array, "thumbnail").indexOf(true);

                                                    // console.log("index>>>", index)

                                                    if (index < 0) {
                                                        array[fakerKey].thumbnail = e.target.checked;
                                                        setImages(array)
                                                    } else {
                                                        array[index].thumbnail = false;
                                                        array[fakerKey].thumbnail = e.target.checked;
                                                        setImages(array)
                                                    }
                                                    // array.filter(person => person.thumbnail === true)
                                                    // array[fakerKey].thumbnail = e.target.checked;
                                                    // setImages(array)
                                                    // console.log("<<<<<",e.target.checked)
                                                    // setlatest(e.target.checked)
                                                    // onChangeUserArr(e, arr.privilege)
                                                    // this.onChangeAllArr(e, arr.privilege);
                                                }}
                                            />
                                           


                                        </div> */}
                                    </>

                                ))}
                            </div>
                            <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative text-sm">
                                {/* <Lucide icon="Image" className="w-4 h-4 mr-2" /> */}
                                <i className="las la-image text-xl text-center  mr-2 flex items-center justify-center"></i>
                                <span className="text-primary mr-1">
                                    Upload image
                                </span>{" "}
                                or drag and drop
                                <input
                                    id="horizontal-form-1"
                                    type="file"
                                    accept={"image/png, image/jpeg"}
                                    className="w-full h-full top-0 left-0 absolute opacity-0"
                                    onChange={(e) => {

                                        let file = e.target.files[0];

                                        let reader = new FileReader();
                                        reader.readAsDataURL(file);
                                        let fileInp = e.target.files[0];
                                        let baseURL;
                                        // on reader load somthing...
                                        reader.onload = () => {
                                            // Make a fileInfo Object
                                            baseURL = reader.result;
                                            let obj =
                                            {
                                                path: baseURL,
                                                thumbnail: false,
                                            }
                                                ;

                                            // setImages(images.concat(obj))
                                            setImages(obj.path)


                                        };
                                    }}
                                />
                            </div>
                        </div>


                        <div className="flex w-full items-center justify-center">
                            <button
                                className={
                                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#f8b611]
        w-full h-9 text-slate-900 font-medium mt-4 `}
                                onClick={addTicket}

                            >
                                {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                                <span className="block  text-sm font-medium "
                                >
                                    Submit
                                </span>

                            </button>
                        </div>

                    </div>

                </div>
            </div>

        </>
    )

}

export default Jobs;